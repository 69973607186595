import 'microtip/microtip.css'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'redux'
import { selectBotData, useAppSelector } from 'src/redux/hooks'
import { WhiteLabelTheme } from '../../reducers/whiteLabel.reducers'
import { useWidgetUiContext } from '../WidgetUiContext'
import CloseBtn from './CloseBtn'
import DisconnectBtn from './DisconnectBtn'
import LocaleSelector from './LocaleSelector'
import MinMaxBtn from './MinMaxBtn'
import NotifBadge from './NotifBadge'
import ResetBtn from './ResetBtn'
import RestartBtn from './RestartBtn'
import ThemeSelector from './ThemeSelector'

interface HeaderBtnsProps {
	store: Store
	userId: string
	props: {
		userAttributes: any
		authToken: string
		botType: string
		botClientType: string
		flowName: string
		botStudioBot: any
		isSupportBot: any
		metadata: any
	}
	theme: WhiteLabelTheme
	hideSendBox: boolean
	isConnetedToAgent: boolean
	setIsConnetedToAgent: (obj: boolean) => void
	hideUploadButton: boolean
	setHideUploadButton: (obj: boolean) => void
	lsAgentChatDeactivate: () => void
}
function ChatHeader({
	store,
	userId,
	theme,
	props,
	hideSendBox,
	isConnetedToAgent,
	setIsConnetedToAgent,
	hideUploadButton,
	setHideUploadButton,
	lsAgentChatDeactivate,
}: HeaderBtnsProps) {
	const { t } = useTranslation()
	const { langs = [] } = useAppSelector(selectBotData) || {}
	const { chatOption, setChatOption } = useWidgetUiContext()

	const toggleChatOption = useCallback(() => {
		setChatOption(!chatOption)
	}, [chatOption, setChatOption])

	const { uiClassNames, chatOptionImage, hideDisconnect, chatMinimize, showResetBtn } = useWidgetUiContext()

	const [isMac,setisMac] = useState(false)
	useEffect(()=>{
		let Mac:any = false;
        if ((navigator as any).userAgentData) {
			Mac = (navigator as any).userAgentData.platform === 'macOS';
			setisMac(Mac)
		  } else {
			 Mac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
			 setisMac(Mac)
		  }
	},[])
			

	return (
		<div className="chat-header">
			<style>
				{`
				span.tooltip1::after{
					 margin-left : -8px;
					}
				span.tooltip2::after{
					 margin-left : -2px;
					}
				span.tooltip3::after{
					 margin-left : -16px;
					}`}
			</style>
			<span
				className="bot-name pull-left bot-header-text"
				aria-label={process.env.REACT_APP_VERSION}
				data-microtip-position="bottom"
				role="tooltip">
				{theme && theme.headerHtmlText ? <div dangerouslySetInnerHTML={{ __html: theme.headerHtmlText }} /> : t('Chat')}
			</span>
			<span className="header-icons pull-right">
				<LocaleSelector
					langs={langs}
					userId={userId}
					userAttributes={props.userAttributes}
					authToken={props.authToken}
					botType={props.botType}
					botClientType={props.botClientType}
					flowName={props.flowName}
				/>
				<NotifBadge botStudioBot={props.botStudioBot} />
				{(!props.isSupportBot && !props.botStudioBot && !props.metadata?.chatHeaderIcon?.notVisibleMaximize) && <span
					aria-label={chatMinimize  ? t(`Maximize(${isMac?'Ctrl':'Alt'}+M)`) : t(`Minimize(${isMac?'Ctrl':'Alt'}+M)`)}
					data-microtip-position="bottom"
					className='tooltip1'
					role="tooltip">
					<MinMaxBtn />
				</span>}
				<ThemeSelector />
				{!hideSendBox && (
					<>
						{(!hideDisconnect || isConnetedToAgent) && (
							<span aria-label={t('Disconnect')} data-microtip-position="bottom" role="tooltip">
								<DisconnectBtn
									store={store}
									hideUploadButton={hideUploadButton}
									setHideUploadButton={setHideUploadButton}
									setIsConnetedToAgent={setIsConnetedToAgent}
									lsAgentChatDeactivate={lsAgentChatDeactivate}
								/>
							</span>
						)}
						{!(!hideDisconnect || isConnetedToAgent) && (
							<span className='tooltip2' aria-label={t(`Restart(${isMac?'Ctrl':'Alt'} + R)`)} data-microtip-position="bottom" role="tooltip">
								<RestartBtn store={store} />
							</span>
						)}
						{!(!hideDisconnect || isConnetedToAgent) && showResetBtn && <ResetBtn />}
						{/* {menuFlow && menuFlow.length > 0 && */}
						<span
							className="chat-options"
							onClick={() => {
								toggleChatOption()
								// refreshRendor()
							}}>
							<img src={chatOptionImage} />
						</span>
					</>
				)}
				{(!props.isSupportBot && !props.botStudioBot) && <span className='tooltip3' aria-label={t('Close(Esc)')} data-microtip-position="bottom" role="tooltip">
					<CloseBtn botStudioBot={props.botStudioBot} />
				</span>}
			</span>
		</div>
	)
}
export default ChatHeader
